import { MutationCache, QueryCache, QueryClient } from 'react-query'

export const queryClient = new QueryClient({
  logger: {
    log: (...args) => {
      // Log debugging information
      console.log(...args)
    },
    warn: (...args) => {
      // Log warning
      console.warn(...args)
    },
    error: (...args) => {
      // Log error
      console.error(...args)
    },
  },
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      disableToast: false,
    },
    message: ({ message, type }) => {
      queryClient.invalidateQueries('toast')
      queryClient.setQueryData('message', () => ({ message, type }))
    },
  },
  queryCache: new QueryCache({
    onError: (e, query) => {
      if (!query?.options?.disableToast)
        if (e?.message === 'Network Error') {
          queryClient.defaultOptions.message({ message: e?.message, type: 'error' })
        } else if (e?.response?.status === 500) {
          queryClient.defaultOptions.message({ message: e?.message, type: 'warning' })
        } else if (e?.response?.status > 300 && e?.response?.status < 500) {
          queryClient.defaultOptions.message({ message: e?.response?.data.message || e?.message, type: 'error' })
        } else if (e?.response?.status <= 500) {
          queryClient.defaultOptions.message({ message: e?.response?.data.message || e?.message, type: 'warning' })
        }
    },
  }),
  mutationCache: new MutationCache({
    onError: (e, query) => {
      if (!query?.options?.disableToast)
        if (e?.message === 'Network Error') {
          queryClient.defaultOptions.message({ message: e?.message, type: 'error' })
        } else if (e?.response?.status === 500) {
          queryClient.defaultOptions.message({ message: e?.message, type: 'warning' })
        } else if (e?.response?.status > 300 && e?.response?.status < 500) {
          queryClient.defaultOptions.message({ message: e?.response?.data.message || e?.message, type: 'error' })
        } else if (e?.response?.status <= 500) {
          queryClient.defaultOptions.message({ message: e?.response?.data.message || e?.message, type: 'warning' })
        }
    },
  }),
})

queryClient.devtools = false


export const route = {
    login: '/login',
    dashboard: '/dashboard',
    venue: '/venue',
    addVenue: dynamicRoutes('venue'),
    ediVenue: dynamicRoutes('venue'),
    viewVenue: dynamicRoutes('venue'),
    faq: '/faq',
    inquiry: '/inquiry',
    cms: '/cms',
    editCms: dynamicRoutes('cms'),
    viewInq: dynamicRoutes('inquiry'),
    transaction: '/transactions',
    profile: '/profile',
    users: '/users',
    editProfile: '/profile/edit',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
}

function dynamicRoutes(module = '') {
    function commonRoutes({ id, type, postfixurl = '' }) {
      let isValidType = ['add', 'edit', 'view', ':type', 'about-us', "privacy-policy", "terms-condition", 'contact-us'].includes(type)
  
      if (!isValidType) throw new Error('Invalid type')
      return `${route?.[module] || module}/${type}${id ? `/${id}` : ''}${postfixurl ? `/${postfixurl}` : ''}`
    }
    return (type, id, postfixurl) => commonRoutes({ id, type, module, postfixurl })
  }
  
import { Crypt } from 'hybrid-crypto-js'
import { queryClient } from '../App'
import { useParams } from "react-router-dom"
import { useMemo } from 'react'
// Function for Verify Length
export function verifyLength(value, length) {
  return value?.length >= length
}

export const months = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
}

export const onlyInt = /\D+/g
export const onlyDigits = /^[0-9]*$/
export const onlyUrl = /^(https?:\/\/)?([a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})+)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/
export const PASSWORD = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/

export function isGranted(allowed) {
  const token = localStorage.getItem('token') || sessionStorage.getItem('token') || queryClient.getQueryData('')
  const data = token ? parseJwt(token) : null

  if (data?.aTotalPermissions?.length) {
    if (Array.isArray(allowed)) {
      const granted = !!allowed.filter((per) => data.aTotalPermissions.includes(per) || per === 'noRole').length
      return granted
    } else {
      const granted = data.aTotalPermissions.includes(allowed) || allowed === 'noRole'
      return granted
    }
  } else if (allowed === 'noRole') {
    return true
  } else {
    return false
  }
}


export function parseJwt(token) {
  try {
    return JSON.parse(window.atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export function checkObjectId(id) {
  return /^[a-f\d]{24}$/i.test(id)
}

export function detectBrowser() {
  var N = navigator.appName,
    ua = navigator.userAgent,
    M = ua.match(/(opera|chrome|safari|firefox|msie)\/?\s*([\d.]+)/i)
   M = M ? [M[1]] : [N, navigator.appVersion, '-?']
  return M.join('')
}

export const rules = {
  global: function (name) {
    return { required: `${name} is required` }
  },
  select: function (name) {
    return { required: `Please select the ${name}` }
  },
  contact: function (name) {
    return {
      ...rules?.global(name),
      pattern: {
        value: /^[0-9]{10}$/,
        message: 'Invalid Contact Number. It should be a 10-digit number.',
      },
    }
  },
  email: function (name) {
    return {
      ...rules?.global(name),
      pattern: {
        value: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/,
        message: 'Invalid email format',
      },
    }
  },
  integer: function (name) {
    return {
      ...rules?.global(name),
      pattern: {
        value: onlyDigits,
        message: 'charges must be in int',
      },
    }
  },
  percentage: function (name) {
    return {
      ...rules?.global(name),
      max: {
        value: 100,
        message: 'Commission must be from 0 - 100%',
      },
    }
  },
  url: function (name) {
    return {
      ...rules?.global(name),
      pattern: {
        value: onlyUrl,
        message: 'url is not valid',
      },
    }
  },
  maxValidation: function (name, maxValue) {
    return {
      ...rules?.global(name),
      max: {
        value: maxValue,
        message: `value must be less than ${maxValue}`,
      },
    }
  },
}

export function toaster(message, type = 'success') {
  queryClient.defaultOptions.message({ message, type })
}

const publicKey = `-----BEGIN PUBLIC KEY-----MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA2J58le2YouwTMrjIhDcWXCmnjy1S+SGihfxwsbW7fdYYLeCAlaZdMTHNm602VXhGQWtsCoEZVJGDLUqKTT8SpIGuVSDbhePYl/kBjzXyndPZ5J1jPg64S90A90X16aafJvbDT0qbPOZrF9PBa4fniKCJFO7V7+yqvZdWcGfIFcPKmUDUnTWTNn7UpGEtKT11jXTSjVCuUFKt8BZqeM+CptPHx+dh6yydo7+Ea+ZvtokUf/je5F34XRMZX0w9UOiQhkDLjQfVeuL975GNR3EZ+1dDciC5ELkb5jKRppHrBx6RNudHuF4eZ7Fvr/gAeFrRQOXUzaM9wpz0q2y+pDQZbmoF376XZJRM210ru+EpJV+DN5jdE23XRG344BIGlTcY4jMy9zUJbDMuA+KeN4VtURecljhUC+GWENs3g65XMK/8f8xhXVJ3sw4S88VK0F14G8xZ3DZQmHpwIMdxB9qbPtECxUK0x0hzoHkzG/mO+L403bK6ylyc4IdlNogzK92o9fxSK/aeBHOd4DMDac+rCzgFt1+7PAx9+SaqtbD2bt2Zd1YlAAhzvNoNemeuAiH+4+99DHhXIvO6jbPiHHKUXJXFFfJCj8l7TPrh5/nBwrL9f22LwikdBE+6UmUMwagLoi3V7S5QAzvhTrCSmGFou2FhX6BmND0lm3vtizleCpsCAwEAAQ==-----END PUBLIC KEY-----`

export function encryption(sPassword) {
  const crypt = new Crypt()
  const encrypted = crypt.encrypt(publicKey, sPassword)
  return encrypted.toString()
}

export function addToken(token) {
  localStorage.setItem('token', token)
}

export function removeToken() {
  localStorage.clear('')
  sessionStorage.clear('')
}

let nav
export function setNav(n) {
  nav = n
}
export function navigationTo(link) {
  nav(link)
}



const dots = '...'

export const range = (start, end) => {
  let length = end - start + 1
  return Array.from({ length }, (_, idx) => idx + start)
}

export const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage }) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize)

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount)
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount)

    /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

    const firstPageIndex = 1
    const lastPageIndex = totalPageCount

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount
      let leftRange = range(1, leftItemCount)

      return [...leftRange, dots, totalPageCount]
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount
      let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)
      return [firstPageIndex, dots, ...rightRange]
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex + 1, rightSiblingIndex + 1)
      return [firstPageIndex, dots, ...middleRange, dots, lastPageIndex]
    }
  }, [totalCount, pageSize, siblingCount, currentPage])

  return paginationRange
}


export const appendParams = (value) => {
  const data = { ...value }
  data.search = encodeURIComponent(data?.search || '')
  Object.keys(data).forEach((e) => (data[e] === '' || typeof data[e] === 'object' || !data[e]?.toString().length) && delete data[e])

  window.history.replaceState({}, null, `${location.pathname}?${new URLSearchParams(data).toString()}`)
}


export function cell(data, optionalText = '-') {
  return data ?? optionalText
}

export const addQueryParams = (value) => {
  const data = { ...value }
  Object.keys(data).forEach((e) => (data[e] === '' || typeof data[e] === 'object' || !data[e]?.toString().length) && delete data[e])
  return new URLSearchParams(data)?.toString()
}

export const parseParams = (params = '') => {
  const urlParams = new URLSearchParams(decodeURIComponent(params))
  const rawParams = decodeURIComponent(params).replace('?', '').split('&')

  const extractedParams = {}
  if (rawParams[0]) {
    rawParams.forEach((item) => {
      item = item.split('=')
      extractedParams[item[0]] = urlParams.get(item[0]) ? urlParams.get(item[0]) : ''
    })
    return extractedParams
  } else {
    return extractedParams
  }
}


// export const parseParams = (params = '') => {
//   const urlParams = new URLSearchParams(params)
//   const array = [
//     'aFilters',
//     'aStatusFiltersInput',
//     'aStatus',
//     'aCountryFilter',
//     'aRoleFilter',
//     'aCodeFilters',
//     'eDesignationFilter',
//     'aCategoryFilters',
//     'aTagFilters',
//     'aFilter',
//     'eState',
//     'aState',
//     'aTeamTagFilters',
//     'aVenueTagFilters',
//     'aSeriesFilters',
//     'aAuthorsFilters',
//     'aType'
//   ]
//   const value = Object.fromEntries(urlParams.entries())
//   Object.keys(value).forEach((key) => {
//     if (array.includes(key)) {
//       value[key] = value[key].split(',')
//     }
//   })
//   return value
// }


export const tokenParams = (params = '') => {
  const rawParams = decodeURIComponent(params).replace('?', '').split('&')
  return rawParams[0]
}

export const prm = (params = '') => {
  const urlParams = new URLSearchParams(params)
  const value = urlParams.entries()
  return value
}

export default function usePageType() {
  const { type, id } = useParams()
  const isAdd = type === 'add'
  const isEdit = type === 'edit'
  const isViewOnly = type === 'view' || (type !== 'add' && type !== 'edit')

  return { isAdd, isEdit, isViewOnly, type, id }
}

// const inputTime = new Date('2023-10-27T12:00:00Z')







export function formatIstTime(date) {

  const utcDate = new Date(date)

  const istFormatted =  utcDate.toLocaleString('en-us', { timeZone: 'Asia/Kolkata' })

  const time = istFormatted.split(',')

  return time
}



export function formatDate(date, separator = '-', reverseDate = false) {
  const d = new Date(date).toString().split(' ')
  const formatted = reverseDate ? [d[2], months[d[1]], d[3]].reverse() : [d[2], months[d[1]], d[3]]
  return date && formatted.join(separator)
}

export function formatTime(dateString) {
  
  const date = new Date(dateString)

  
  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()
  const seconds = date.getUTCSeconds()


  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`

  return formattedTime
}



// export function formateTime(date, separator = ':') {
//   const t 
// }

export function emitEvent(type, detail = {}, elem = document) {
  if (!type) return

  let event = new CustomEvent(type, {
    bubbles: true,
    cancelable: true,
    detail: detail,
  })

  return elem.dispatchEvent(event)
}
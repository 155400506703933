import { lazy } from "react"
import { route } from '../Routes/Route'

const PublicRoute = lazy(() => import('./PublicRoute'))
const PrivateRoute = lazy(() => import('./PrivateRoute'))

// public Routes Files
const Login = lazy(() => import('../Pages/Auth/Login'))
const ForgotPassword = lazy(() => import('Pages/Auth/ForgotPassWord'))
const ResetPassWord = lazy(() => import('Pages/Auth/ResetPassword'))

// Private Routes
const Dashboard = lazy(() => import('../Pages/Dashboard'))
const Venue = lazy(() => import('../Pages/Venue'))
const AddVenue = lazy(() => import('../Pages/Venue/Add-Edit-Venue'))
const Faq = lazy(() => import('../Pages/Faq'))
const Inquiry = lazy(() => import('../Pages/Inquiry'))
const viewInq = lazy(() => import('../Pages/Inquiry/ViewInquiry'))
const Cms = lazy(() => import('../Pages/CMS'))
const editCms = lazy(() => import('../Pages/CMS/View-Edit-Cms'))
const Transaction = lazy(() => import('Pages/Transaction'))
const Profile = lazy(() => import('Pages/Profile'))
const Users = lazy(() => import('Pages/Users'))
const EditProfile = lazy(() => import('Pages/Profile/EditProfile'))

const RoutesDetails = [
    {
      defaultRoute: '',
      Component: PublicRoute,
      props: {},
      isPrivateRoute: false,
      children: [
        { path: route.login, Component: Login, exact: true },
        {path: route.forgotPassword, Component: ForgotPassword, exact: true },   
        {path: route.resetPassword, Component: ResetPassWord, exact: true }        
      ],
    },
    {
      defaultRoute: '',
      Component: PrivateRoute,
      props: {},
      isPrivateRoute: true,
      children: [
       {path: route.dashboard, Component:Dashboard, exact: true },
       {path: route.venue, Component:Venue, exact: true },
       {path: route.faq, Component:Faq, exact: true},
       {path: route.addVenue(':type'), Component: AddVenue, exact: true},
       {path: route.ediVenue(':type',':id'), Component: AddVenue, exact: true},
       {path: route.viewVenue(':type',':id'), Component: AddVenue, exact: true},
       {path: route.inquiry, Component:Inquiry, exact: true},
       {path: route.viewInq(':type',':id'), Component: viewInq, exact: true},        
       {path: route.cms, Component:Cms, exact: true},
       {path: route.editCms((':type')), Component:editCms, exact: true},        
       {path: route.transaction , Component:Transaction, exact: true},        
       {path: route.profile , Component:Profile, exact: true},        
       {path: route.users , Component:Users, exact: true},
       {path: route.editProfile, Component: EditProfile, exact: true},
      ],
    },
  ]

  export default RoutesDetails

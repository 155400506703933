/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import RoutesDetails from './Routes'
import Loading from '../Components/Loading'
import { isGranted } from '../helpers'
import { route } from './Route'
import Toaster from '../Components/Toaster'

function AllRoutes() {
  // function allPaths(children, isPrivateRoute) {
  //   return children?.map(({ path, Component, exact, props, allowed, children: child }, i) =>
  //     child?.length ? (
  //       <Route key={i} element={<Component />}>{allPaths(child, isPrivateRoute)}</Route>
  //     ) : isGranted(allowed) || !isPrivateRoute ? (
  //       <Route
  //         key={i}
  //         path={path}
  //         element={
  //           <Suspense fallback={<Loading />}>
  //             <Component {...props} />
  //           </Suspense>
  //         }
  //         exact={exact}
  //       />
  //     ) : null
  //   )
  // }

  function allPaths(children) {
    return children?.map(({ path, Component, exact, props, children: child }, index) => {
      return child?.length ? (
        <Route element={<Component />} key={index}>
          {allPaths(child)}
        </Route>
      ) : (
        <Route
          key={path}
          path={path}
          element={
            <Suspense fallback={<Loading />}>
              <Component {...props} />
            </Suspense>
          }
          exact={exact}
        />
      )
    })
  }

  return (
    <>
     <Toaster limit={3} />
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Navigate to={route.dashboard} />} />
          {RoutesDetails?.map(({ isPrivateRoute, children, Component }, i) => {
            return (
              <Route key={isPrivateRoute ? 'private' + i : 'public' + i} element={<Component />}>
                {allPaths(children, isPrivateRoute)}
              </Route>
            )
          })}
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default AllRoutes

/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react'
import userReducers from './reducers/userReducers'
import './Assets/Style/style.scss'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClientProvider } from 'react-query'
import { userContext } from './context/user'
import { queryClient } from './queryClient'
import AllRoutes from './Routes'
import Loading from './Components/Loading'

function App() {
  const [state, dispatch] = React.useReducer(userReducers)

  return (
    <QueryClientProvider contextSharing={true} client={queryClient}>
      <userContext.Provider value={{ state, dispatch }}>
      <AllRoutes />
      {queryClient?.devtools ? <ReactQueryDevtools initialIsOpen={false} /> : null}
      </userContext.Provider>
    </QueryClientProvider>
  )
}
export { queryClient }

export default App
